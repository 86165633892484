<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Offerte</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='id' header='ID' :sortable='true' style='width:3rem'/>

                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                    </Column>

                    <Column field='locale' header='Lingua' :sortable='true' style='width:8rem'>
                        <template #body='slotProps'>
                            <FlaggedLanguage :lang='slotProps.data.lang'/>
                        </template>
                    </Column>

                    <Column  header='Link' :sortable='false' style='width:8rem'>
                        <template #body='slotProps'>
                            <a :href='getFrontendUrl("/"+slotProps.data.lang+"/"+getPromoString(slotProps.data.lang)+"/" + slotProps.data.slug)' target='_blank'>Preview</a>
                        </template>
                    </Column>

                    <Column field='active' header='Attiva' :sortable='true' style='width:4rem'>
                        <template #body='slotProps'>
                            {{ Formatters().bool2YesNo(slotProps.data.active) }}
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        /></template>

                        <template #body='slotProps'>

                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>

                            <Button icon='pi pi-clone' class='mr-2 mb-2 p-button-primary' title='Clona questa offerta'
                                    @click='cloneOffer(slotProps.data.id)'></Button>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Web/Offers');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';

import FlaggedLanguage from '../../../components/4books/FlaggedLanguage';
import ListHeader from '../../../components/4books/ListHeader';

export default {
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'clone']),
        getPromoString(lang){
            if (lang === "en") return "offer";
            if (lang === "es") return "promo";
            return "offerta";
        },
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        cloneOffer(id){
            this.clone(id).then(() => {
                this.ShowSuccess("Fatto", "Offerta clonata")
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    mixins: [
        Notifications,
        Navigations,
    ],
    components: {
        FlaggedLanguage,
        ListHeader
    }
};
</script>
